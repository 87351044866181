import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpErrorHandler, HandleError} from '../../../services/http-error-handler.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {StorageService} from "../../../services/storage.service";
import {constLocalStorage} from "../../../shared/entity.const";


// Благодаря такому декоратору @Injectable мы можем инжектировать в данный сервис другие сущности
@Injectable({
    // Данной строкой мы говорим чтобы ангуляр зарегал данный сервис в корневом модуле (в системе в единственном экз.).
    providedIn: 'root'
    // Поэтому из корневого модуля можно исключить QuestionService так как он регается тут
    // Данный сервис зареган глобально и виден во всех компонентах
    // Можно регать сервисы локально (каждый раз создается копия сервиса с новыми данными) в конкретном компоненте тогда нужно в конкретный компонент в декоратор
    //  @Component({
    //    providers: [QuestionService],
    //  })
    // Также можно использовать сервисы внутри других сервисов. Внедрение через конструктор
})

export class QuestionService {

    private readonly handleError: HandleError;
    questionUrl = '/api/v1/question/';  // URL to web api

    // Переменная содержит выпадающий список
    meetingType: any
    questionSolution: any //Критерий принятия решения

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = httpErrorHandler.createHandleError('QuestionService');
    }

    getTheme() {
        return document.body.getAttribute('theme');
    }

    getStandartQuestion(detail: any): Observable<any> {
        const url = `${this.questionUrl + 'getStandartQuestion'}`;

        return this.http.post(url, {
            params: {
                houseId: detail.houseId
            }
        })
            .pipe(
                catchError(this.handleError('getStandartQuestion', []))
            );
    }

    getMyQuestionCurrentMeeting(): Observable<any> {
        const url = `${this.questionUrl + 'getMyQuestionCurrentMeeting'}`;

        return this.http.post(url, {
            params: {
                user_id: this.storageService.getUser(),
            }
        })
            .pipe(
                catchError(this.handleError('getMyQuestionCurrentMeeting', []))
            );
    }

    getMyQuestion(): Observable<any> {
        const url = `${this.questionUrl + 'getMyQuestion'}`;

        return this.http.post(url, {
            params: {
                user_id: this.storageService.getUser(),
            }
        })
            .pipe(
                catchError(this.handleError('getMyQuestion', []))
            );
    }


    getQuestionsMeeting(detail: any): Observable<any> {
        return this.http.post(this.questionUrl + 'getQuestionsMeeting', {
            params: {
                meetingId: Number(detail.meetingId),
                userId: detail.userId,
            }
        })
            .pipe(
                catchError(this.handleError('getQuestionsMeeting', []))
            );
    }

    getVoteResults(detail: any): Observable<any> {
        return this.http.post(this.questionUrl + 'getVoteResults', {
            params: {
                meetingId: Number(detail.meetingId),
                userId: Number(detail.userId),
                houseId: Number(detail.houseId)
            }
        })
            .pipe(
                catchError(this.handleError('getVoteResults', []))
            );
    }


    /**
     * Update голос у конкретного вопроса ВОЗДЕРЖАЛСЯ/ЗА/ПРОТИВ
     * @param params
     */
    updateMeetingVote(params: any): Observable<{}> {
        const url = `${this.questionUrl + 'updateMeetingVote'}`;
        return this.http.post(url, {
            params: {
                "meeting_id": params.meetingId,
                "owner_id": params.ownerId,
                "question_id": params.questionId,
                "vote_yes": params.vote_yes,
                "vote_no": params.vote_no,
                "vote_unknown": params.vote_unknown,
            }
        })
            .pipe(
                catchError(this.handleError('updateMeetingVote'))
            );
    }

    /**
     * Update вопросов у конкретного собрания
     * @param detail
     */
    updateQuestionValue(detail: any): Observable<{}> {
        const url = `${this.questionUrl + 'updateQuestionValue'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('updateQuestionValue'))
            );
    }

    /**
     * Update моих вопросов
     * @param detail
     */
    updateMyQuestionValue(detail: any): Observable<{}> {
        const url = `${this.questionUrl + 'updateMyQuestionValue'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('updateMyQuestionValue'))
            );
    }


    /**
     * Создать вопрос в конкретном собрании
     * @param detail
     */
    createQuestion(detail: any): Observable<any> {
        const url = `${this.questionUrl + 'createQuestion'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('createQuestion'))
            );
    }

    /**
     * Создать вопрос в моих вопросах шаблон
     * * @param detail
     */
    createMyQuestion(detail: any): Observable<any> {
        const url = `${this.questionUrl + 'createMyQuestion'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('createMyQuestion'))
            );
    }

    copyQuestionsMeeting(detail: any): Observable<any> {
        const url = `${this.questionUrl + 'copyQuestionsMeeting'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('copyQuestionsMeeting'))
            );
    }

    addStandartQuestionToCurrentMeeting(detail: any): Observable<any> {
        const url = `${this.questionUrl + 'addStandartQuestion'}`;
        return this.http.post(url, {
            params: {
                "meeting_id": Number(detail.meeting_id),
                "user_id": Number(detail.user_id),
                "selectedStandartQuestionsIds": detail.selectedStandartQuestionsIds,
            }
        })
            .pipe(
                catchError(this.handleError('addStandartQuestion'))
            );
    }

    addMyQuestionToCurrentMeeting(detail: any): Observable<any> {
        const url = `${this.questionUrl + 'addMyQuestion'}`;
        return this.http.post(url, {
            params: {
                "meeting_id": Number(detail.meeting_id),
                "user_id": Number(detail.user_id),
                "selectedMyQuestionsIds": detail.selectedMyQuestionsIds,
            }
        })
            .pipe(
                catchError(this.handleError('addMyQuestion'))
            );
    }

    /**
     * Удалить вопрос у конкретного собрания
     * @param detail
     */
    deleteQuestion(params: any): Observable<{}> {
        const url = `${this.questionUrl + 'deleteQuestion'}/${params.id}`;
        return this.http.post(url,
            {
                params: {
                    "id": params.id,
                    "meeting_id": params.meeting_id,
                }
            })
            .pipe(
                catchError(this.handleError('deleteQuestion'))
            );
    }

    /**
     * Удалить мой вопрос из шаблонов
     * * @param detail
     */
    deleteMyQuestion(id: any): Observable<{}> {
        const url = `${this.questionUrl + 'deleteMyQuestion'}/${id}`;
        return this.http.post(url,
            {
                params: {
                    "id": id,
                }
            })
            .pipe(
                catchError(this.handleError('deleteMyQuestion'))
            );
    }

    deleteQuestions(params: any): Observable<{}> {
        const url = `${this.questionUrl + 'deleteQuestions'}`;
        return this.http.post(url, {
            params: {
                "ids": params.ids,
                "meeting_id": params.meeting_id,
                "count_ids": params.countIds,
            }
        })
            .pipe(
                catchError(this.handleError('deleteQuestions'))
            );
    }

    deleteMyQuestions(ids: any): Observable<{}> {
        const url = `${this.questionUrl + 'deleteMyQuestions'}`;
        return this.http.post(url, {
            params: {
                "ids": ids,
            }
        })
            .pipe(
                catchError(this.handleError('deleteMyQuestions'))
            );
    }
/*    updateMeeting(meeting: Meeting): Observable<any> {
        return this.http.post(this.questionUrl, meeting)
            .pipe(
                catchError(this.handleError('updateMeeting', meeting))
            );
    }*/

    /**
     * Получить тип собрания
     */
    getMeetingType(): Observable<{}> {
        const url = `${this.questionUrl + 'getMeetingType'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

    /**
     * Получить критерии принятия решения
     */
    getQuestionSolutions(): Observable<any> {
        const url = `${this.questionUrl + 'getQuestionSolutions'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

}
